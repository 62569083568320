import ApiService from "@/core/services/api.service";
import API_LINKS from "../api.links";
export default class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });
          const base64_image = toBase64(file).then((data) => {
            ApiService.post(`${API_LINKS.contasAtivas}/upload-arquivo`, {
              arquivo: data,
              caminho: "modelo-campanha/"
            }).then((res) => {
              const url = res.data.url;
              resolve({
                default: url
              });
            });
          });
          this.loader.uploaded = true;
          return base64_image;
        })
    );
  }
}
